import React from 'react';

import CookieConsent from "react-cookie-consent";
import Breadcrumb from './Breadcrumb';
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import ArtworkArea from './ArtworkArea';

function Overview() {
  return (
	<>
		<HeaderTwo/>
		<main>
			<Breadcrumb/>
			<ArtworkArea/>
			<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

		</main>
		<Footer/>
	</>
  )
}

export default Overview
import React from 'react'
import { Link } from 'react-router-dom';


function Breadcrumb() {
  return (
	
      <div className="breadcrumb-area breadcrumb-bg game-overview-breadcrumb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-6">

            </div>
            <div className="col-xl-8 col-lg-6">
              <div className="breadcrumb-content text-center text-lg-left pl-80">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">OATTM - AI Generated Artwork</li>
                  </ol>
                </nav>
                <h2>Free <span>Downloads</span></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Breadcrumb
import React from 'react'

function SingleArea() {
  return (
	<section className="game-single-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="game-single-content">
                <div className="upcoming-game-head">
                  <div className="uc-game-head-title">
                    <span>November 14, 2023</span>
                    <h4>Oliver and the Time Machine <span>The Beginning</span></h4>
                  </div>
                  <div className="uc-game-price">
                  <a href="https://books2read.com/oattm" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><h5>Buy from £2.99 paperback or ebook</h5></a>
                  </div>
                </div>
                <p><span>Book one of a three part series</span> - Oliver's life takes an extraordinary turn when he discovers a time-traveling car with an AI named Rosie.
                 Together, they journey through various epochs, from prehistoric ages to steampunk realities and futuristic worlds.
                  However, their travels aren't just about curiosity; they're on a mission. 
                  The villainous Drake Cybernox, a tech magnate from the future, seeks to control the past and the future, at all costs.</p>
                   <p>As Oliver and Rosie endeavour to thwart his plans, they navigate a maze of challenges, friends, foes, and revelations.
                    With each leap through time, Oliver learns more about the interconnectedness of history, the ripple effects of actions, and the potential one boy has to shape the future.
                     Dive into a thrilling adventure where the stakes are high, and the journey through time is both a lesson and a quest.</p>
                <div className="game-single-img">
                  <div className="row">
                    <div className="col-sm-6">
                      <img src="assets/img/images/book_single_img01.jpg" alt="" />
                    </div>
                    <div className="col-sm-6">
                      <img src="assets/img/images/book_single_img02.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <p>Images in the book are generated using AI and remastered in Photoshop (see below)</p>
                <div className="game-single-title mt-60 mb-30">
                  <h4>Information</h4>
                </div>
                <div className="game-single-info mb-65">
                  <ul>
                    <li><span>Characters :</span> Oliver Stone, Drake Cybernox, Rosie the AI, Mr Knowsley, Jenny Driffield</li>
                    <li><span>Theme :</span> Space and Time Travel adventures</li>
                    <li><span>Format :</span> Paperback, Kindle, E-book</li>
                    <li><span>Reading age :</span> Suited to Middle Grades 8 to 12 years, independent readers and reluctant readers</li>
                  </ul>
                </div>
                <div className="game-single-title mb-30">
                  <h4>Image Generation <span>Workflow</span></h4>
                </div>

                <p>Oliver and the Time Machine features AI (Artificial Intelligence) generated images to aid and enhance the storylines. Thousands of images were generated during the writing of book one, with approximately 50 images being used. You can view a video of the image workflow <a href="https://www.youtube.com/watch?v=f7W1dUfsW6w"> here</a></p>
                <p> <strong>Why use AI?</strong></p>
                <p>As the author, there were several reasons why I chose AI to generate the images. The time taken to create an image (very fast), cost and the ability to create what I had in my mind were just some of the main factors. </p>
                <p>During the book writing journey, I found that it also enhanced <strong>creativity and innovation:</strong> With AI, it's possible to generate unique, imaginative visual content that human designers might not think of.
                   This capability can push the boundaries of traditional design and storytelling, leading to fresh, innovative content.</p>
                   <p>Below are some of the images that were generated during the book writing process. These are some of the earliest images and vary from the actual images used in book one. Check AI Artwork in the menu for FREE downloadable images </p>
                <div className="game-single-gallery">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery01.jpg" alt="Early Oliver" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery02.jpg" alt="Early wristband" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery03.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery04.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery05.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery06.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery07.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery08.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery09.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="game-single-shape"><img src="assets/img/images/game_section_shape.png" alt="" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default SingleArea
import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo';
import BreadCrumb from './BreadCrumb';
import Footer from '../../components/footer/Footer';

import OATTMVideos from './OATTMVideos';


function AiVideo() {
  return (
	<>
	<HeaderTwo/>
	<BreadCrumb/>
	<main>
	<OATTMVideos/>
	</main>
	<Footer/>
	</>
  )
}

export default AiVideo
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeTwo from './pages/homes/HomeTwo';
import Shop from './pages/store/Shop';
import Artwork from './pages/artwork/Overview';
import BookSingle from './pages/singlebook/BookSingle';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy'
import AiVideo from './pages/videos/AiVideo';
import Skins from './pages/roblox/Skins';

//import Loader from "react-js-loader";

// Other imports remain unchanged

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeTwo />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/artwork' element={<Artwork />} />
        <Route path='/book-single' element={<BookSingle />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/roblox' element={<Skins />} />

        <Route path='/videos' element={<AiVideo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

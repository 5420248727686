import React from 'react'

function ReleaseBook() {
  return (
	<section className="upcoming-games-area pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="game-single-title mb-55">
                <h4>released <span>Books</span></h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="upcoming-game-item mb-40">
                <div className="upcoming-game-head">
                  <div className="uc-game-head-title">
                    <span>November 14, 2023</span>
                    <h4><a href="https://www.amazon.co.uk/dp/1738510514" target="_blank" rel="noopener noreferrer">The Beginning (Paperback)</a></h4>
                  </div>
                  <div className="uc-game-price">
                    <h5>From £7.99</h5>
                  </div>
                </div>
                <p>First book in the Oliver and the Time Machine series, paperback version</p>
                <div className="upcoming-game-thumb">
                  <img src="assets/img/images/released_book_thumb_the_beginning.jpg" alt="" />
                  <div className="upcoming-game-cart">
                    <a href="https://books2read.com/oattm" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="upcoming-game-item mb-40">
                <div className="upcoming-game-head">
                  <div className="uc-game-head-title">
                    <span>Release date: December 15, 2023</span>
                    <h4><a href="https://books2read.com/oattm" target="_blank" rel="noopener noreferrer" >The Beginning (e-book)</a></h4>
                  </div>
                  <div className="uc-game-price">
                    <h5>From £2.99</h5>
                  </div>
                </div>
                <p>First book in the Oliver and the Time Machine series, Kindle, E-book version</p>
                <div className="upcoming-game-thumb">
                  <img src="assets/img/images/released_book_thumb_the_beginning.jpg" alt="" />
                  <div className="upcoming-game-cart">
                    <a href="https://books2read.com/oattm" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ReleaseBook
import React from 'react'

function ShopArea() {
  return (
	<section className="shop-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="/#"><img src="assets/img/product/organic-cotton-kids-t-shirt-oattm-white.png" alt="" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="/#">OATTM White T-Shirt</a></h5>
                  <span>Price: £16.00</span>
                  <a href="/#" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="/#"><img src="assets/img/product/organic-cotton-kids-t-shirt-oattm-grey.png" alt="" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="/#">OATTM T-Shirt</a></h5>
                  <span>Price: £16.00</span>
                  <a href="/#" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="/#"><img src="assets/img/product/kids-hoodie-jet-black-oattm.png" alt="" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="/#">OATTM Hoodie</a></h5>
                  <span>Price: £28.00</span>
                  <a href="/#" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
  )
}

export default ShopArea
import React from 'react'
import {Link} from 'react-router-dom'



function OattmMain() {

  
  return (
	 <section className="featured-game-area position-relative pt-115 pb-90">
   

            <div className="featured-game-bg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>Book  <span>Series</span></h2>
                    <p>Follow the adventures of Oliver and the Time Machine</p>
                  </div>
                </div>
              </div>
              <div className="row featured-active">
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_book_thumb01.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">Book <span>Three</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>In Progress</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <h4><Link to="/#">Book <span>Three</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Final book in series</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_book_thumb02.jpg" key='1' alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">Book <span>One</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>#Paperback - #Kindle - #Apple - #ebook</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <a href="https://books2read.com/oattm" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now From £2.99</a>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Published</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_book_thumb03.jpg" key="1" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">Book <span>Two</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>In progress</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <h4><Link to="/#">Book <span>Two</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Second book in series</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
  )
}

export default OattmMain
import React,{ useEffect } from 'react'
import $ from "jquery";
import 'magnific-popup';

const OattmVideos = () => {

  useEffect(()=>{

		$('.popup-video').magnificPopup({
			type: 'iframe'
		});
	},[])
  
  return (
    <section className="trending-gamepay-area">
        <div className="container">
          <div className="row align-items-center mb-30">
            <div className="col-sm-6">
              <div className="hf-section-title">
                <h4 className="title">OATTM Short Videos</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="trending-gameplay-item mb-50">
                <div className="gameplay-thumb">
                  <a href="https://www.youtube.com/watch?v=f7W1dUfsW6w" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_working_vid.jpg" alt="Oliver and the Time Machine AI workflow" />
                  </a>
                  <div className="treand-gameplay-overlay">
                    <ul>
                      <li className="duration">01 :45</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="gameplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=f7W1dUfsW6w" target="_blank" rel="noopener noreferrer">Book one image workflow using Midjourney</a></h5>
                    <div className="gameplay-meta">
                      <ul>
                        <li>November 14, 2023</li>
                      </ul>
                    </div>
                  </div>
                  <div className="gameplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="trending-gameplay-item mb-50">
                <div className="gameplay-thumb">
                  <a href="https://www.youtube.com/watch?v=BCXnIHs0MAw" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_book1_intro.jpg" alt="Oliver and the Time Machine book introduction" />
                  </a>
                  <div className="treand-gameplay-overlay">
                    <ul>
                      <li className="duration">08 :08</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="gameplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=BCXnIHs0MAw" target="_blank" rel="noopener noreferrer">Oliver and the Time Machine Book 1 intro</a></h5>
                    <div className="gameplay-meta">
                      <ul>
                        <li>November 14, 2023</li>
                      </ul>
                    </div>
                  </div>
                  <div className="gameplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="trending-gameplay-item mb-50">
                <div className="gameplay-thumb">
                  <a href="https://www.youtube.com/watch?v=WU0DH_RGe0Y" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_drake_androids_intro.jpg" alt="Drake with android guards from the book Oliver and the Time Machine" />
                  </a>
                  <div className="treand-gameplay-overlay">
                    <ul>
                      <li className="duration">00:12</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="gameplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=WU0DH_RGe0Y" target="_blank" rel="noopener noreferrer">Drake with android guards from the book Oliver and the Time Machine - Like Clockwork</a></h5>
                    <div className="gameplay-meta">
                      <ul>
                        <li>November 19, 2023</li>
                      </ul>
                    </div>
                  </div>
                  <div className="gameplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="trending-gameplay-item mb-50">
                <div className="gameplay-thumb">
                  <a href="https://youtube.com/watch?v=iLAsHKCvTl0" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_drake_weather_machine_intro.jpg" alt="Short relating to book chapter - BACK TO THE FUTURE LET IT RAIN" />
                  </a>
                  <div className="treand-gameplay-overlay">
                    <ul>
                      <li className="duration">00:11</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="gameplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=iLAsHKCvTl0" target="_blank" rel="noopener noreferrer">Short relating to book chapter - BACK TO THE FUTURE LET IT RAIN</a></h5>
                    <div className="gameplay-meta">
                      <ul>
                        <li>November 18, 2023</li>
                      </ul>
                    </div>
                  </div>
                  <div className="gameplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
  )
}

export default OattmVideos
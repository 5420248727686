import React from 'react'

function ArtworkArea() {
  return (
	<section className="game-single-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="game-single-content">

                <div className="game-single-title mb-30">
                  <h4>Images Generated <span>Book one</span></h4>
                </div>
                   <p>You are welcome to download and use any images here for personal use. That means anything not for resale</p>
                   <p>Just click the download button for a high-resolution image</p>
                <div className="game-single-gallery">
                  <div className="row">
                  <div className="col-lg-4 col-sm-6">
                  <img src="assets/img/website_download/oattm_small/sm_oattm_oliver_car.jpg" alt="Oliver and car" />
                  <a href="assets/img/website_download/oattm_oliver_car.jpg" download className="btn btn-style-two">Download</a>
          </div>

                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_android_assembly_book1.jpg" alt="Android assembly" />
                      <a href="assets/img/website_download/oattm_android_assembly_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_strings_logo2.jpg" alt="strings of time and space" />
                      <a href="assets/img/website_download/oattm_strings_logo2.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_screensaver_weather_cube_book1.jpg" alt="Drakes weather machine" />
                      <a href="assets/img/website_download/oattm_screensaver_weather_cube_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_velociraptor1_book1.jpg" alt="velociraptor" />
                      <a href="assets/img/website_download/oattm_velociraptor1_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_old_drake_androids_book1.jpg" alt="older Drake" />
                      <a href="assets/img/website_download/oattm_old_drake_androids_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_diplodocus_book1.jpg" alt="Diplodocus" />
                      <a href="assets/img/website_download/oattm_diplodocus_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_pterodactyl_book_1.jpg" alt="Pterodactyl" />
                      <a href="assets/img/website_download/oattm_pterodactyl_book_1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_temporal_resonator_book1.jpg" alt="Temporal Resonator" />
                      <a href="assets/img/website_download/oattm_temporal_resonator_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_steampunk_bmx_2_book1.jpg" alt="Steampunk BMX 2" />
                      <a href="assets/img/website_download/oattm_steampunk_bmx_2_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_screensaver_futuristic_city_1.jpg" alt="Futuristic City" />
                      <a href="assets/img/website_download/oattm_screensaver_futuristic_city_1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_pterodactyl_crisps_book1.jpg" alt="Pterodactyl with crisps" />
                      <a href="assets/img/website_download/oattm_pterodactyl_crisps_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_red_car_glow_book1.jpg" alt="Red car time machine" />
                      <a href="assets/img/website_download/oattm_red_car_glow_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_steampunk_bmx_1_book1.jpg" alt="Steampunk BMX" />
                      <a href="assets/img/website_download/oattm_steampunk_bmx_1_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_younger_drake_androids_book1.jpg" alt="Drake Cybernox with Androids" />
                      <a href="assets/img/website_download/oattm_younger_drake_androids_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_oliver_futuristic_glasses.jpg" alt="Oliver with futuristic glasses" />
                      <a href="assets/img/website_download/oattm_oliver_futuristic_glasses.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_martian_habitat_book1.jpg" alt="Martian habitat" />
                      <a href="assets/img/website_download/oattm_martian_habitat_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_drake_book_1.jpg" alt="Drake Cybernox" />
                      <a href="assets/img/website_download/oattm_drake_book_1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_futuristic_wristband_book1.jpg" alt="Futuristic wristband" />
                      <a href="assets/img/website_download/oattm_futuristic_wristband_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_steampunk_bmx_book1.jpg" alt="Cyberpunk BMX" />
                      <a href="assets/img/website_download/oattm_steampunk_bmx_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_dinosaur_destruction_book1.jpg" alt="dinosaur destruction" />
                      <a href="assets/img/website_download/oattm_dinosaur_destruction_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_strings_logo.jpg" alt="Time travel strings" />
                      <a href="assets/img/website_download/oattm_strings_logo.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_velociraptor_book1.jpg" alt="Velociraptor scary" />
                      <a href="assets/img/website_download/oattm_velociraptor_book1.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_futuristic_city.jpg" alt="Futuristic City 2" />
                      <a href="/assets/img/website_download/oattm_futuristic_city.jpg" download className="btn btn-style-two">Download</a>
                    </div>
                  </div>
                </div>
                <div className="game-single-shape"><img src="assets/img/images/game_section_shape.png" alt="" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ArtworkArea
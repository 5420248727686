import React from 'react';
import CookieConsent from "react-cookie-consent";
import Footer from '../../footer/Footer';
import HeaderTwo from '../../header/HeaderTwo';
import SliderTwo from './SliderTwo';
import OattmMain from './OattmMain'
import OattmMainTwo from './OattmMainTwo';


function IndexTwo(){
	return(

   
    

        <>
         {/* header-area */}
          <HeaderTwo/>
        
       
      
        {/* main-area */}
        <main>
          {/* slider-area */}
          <SliderTwo/>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          
          {/* slider-area-end */}

          {/* featured-game-area */}
         <OattmMain/>
          {/* featured-game-area-end */}

          {/* gamers-area */}
          <OattmMainTwo/>
        {/* gamers-area-end */}

         {/* 
          <CtaArea/>
          <NewGamesArea/>
         <ShopAreaTwo/>
          <BlogArea/> */}

        </main>
        {/* main-area-end */}
        <Footer/>
        </>
	)
}
	
export default IndexTwo
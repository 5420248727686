import React from 'react'

function RobloxArea() {
  return (
	<section className="shop-area pt-120 pb-90">
        <div className="container">
          <div className="row">
          <div className="col-12">
          <div className="game-single-content">
          <p>My kids love Roblox, so I made some OATTM themed clothing (more coming soon, I'm still figuring Roblox out)</p>
                   <p>Just click the item for the link to the marketplace or click <a href="https://www.roblox.com/catalog?Keyword=OATTM&Category=1&salesTypeFilter=1" target="_blank" rel="noopener noreferrer">HERE</a> for a link to all items by OATTM1 </p><p>Unfortunately, Roblox doesn't allow FREE, so I had to price them at the minimum 5 Robux. Free classic T-Shirt png files are at the bottom of the page, Enjoy!</p>
                   </div>
                   </div>
            <div className="col-lg-3 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="https://www.roblox.com/catalog/15361552797/OATTM-Plain-black-white-shirt-with-logo" target="_blank" rel="noopener noreferrer"><img src="assets/img/images/oattm_roblox_black_white_shirt.png" alt="OATTM Roblox Black White Shirt" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="https://www.roblox.com/catalog/15361552797/OATTM-Plain-black-white-shirt-with-logo" target="_blank" rel="noopener noreferrer">OATTM Roblox Black White Shirt</a></h5>
                  <span>Price: 5 Robux</span>
                  <a href="https://www.roblox.com/catalog/15361552797/OATTM-Plain-black-white-shirt-with-logo" target="_blank" rel="noopener noreferrer" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="https://www.roblox.com/catalog/15361197446/OATTM-Shirt-black-logo-hoodie" target="_blank" rel="noopener noreferrer" ><img src="assets/img/images/oattm_roblox_black_shirt.png" alt="OATTM Roblox Black Shirt" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="https://www.roblox.com/catalog/15361197446/OATTM-Shirt-black-logo-hoodie" target="_blank" rel="noopener noreferrer" >OATTM Roblox Black Shirt</a></h5>
                  <span>Price: 5 Robux</span>
                  <a href="https://www.roblox.com/catalog/15361197446/OATTM-Shirt-black-logo-hoodie" target="_blank" rel="noopener noreferrer" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="https://www.roblox.com/catalog/15363506453/OATTM-Velociraptor-Shirt-Green" target="_blank" rel="noopener noreferrer"><img src="assets/img/images/oattm_roblox_green_velociraptor_shirt.png" alt="OATTM Green Velociraptor Shirt" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="https://www.roblox.com/catalog/15363506453/OATTM-Velociraptor-Shirt-Green" target="_blank" rel="noopener noreferrer" >OATTM Green Velociraptor Shirt</a></h5>
                  <span>Price: 5 Robux</span>
                  <a href="https://www.roblox.com/catalog/15363506453/OATTM-Velociraptor-Shirt-Green" target="_blank" rel="noopener noreferrer" className="shop-add-action">Coming Soon</a>
                </div>
              </div>
            </div>

            <div className="col-12">
          <div className="game-single-content">
          <p><span>FREE Roblox Classic T-Shirts</span></p>
                   <p>Just click the download button for the png file ready to upload to Roblox studio</p>
                   </div>
                   </div>
                   <div className="game-single-gallery">
                  <div className="row">

                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_roblox_classic_t-shirt_velociraptor_free.jpg" alt="oattm_roblox_classic_t-shirt_velociraptor_free" />
                      <a href="assets/img/website_download/oattm_roblox_classic_t-shirt_velociraptor_free.png" download className="btn btn-style-two">Download Roblox T-Shirt</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_roblox_classic_t-shirt_BMX_free.jpg" alt="oattm_roblox_classic_t-shirt_BMX_free" />
                      <a href="assets/img/website_download/oattm_roblox_classic_t-shirt_BMX_free.png" download className="btn btn-style-two">Download Roblox T-Shirt</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_roblox_classic_t-shirt_bobble_free.jpg" alt="oattm_roblox_classic_t-shirt_bobble_free" />
                      <a href="assets/img/website_download/oattm_roblox_classic_t-shirt_bobble_free.png" download className="btn btn-style-two">Download Roblox T-Shirt</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_roblox_classic_t-shirt_drake_free.jpg" alt="oattm_roblox_classic_t-shirt_drake_free" />
                      <a href="assets/img/website_download/oattm_roblox_classic_t-shirt_drake_free.png" download className="btn btn-style-two">Download Roblox T-Shirt</a>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/website_download/oattm_small/sm_oattm_roblox_classic_t-shirt_car_free.jpg" alt="oattm_roblox_classic_t-shirt_car_free" />
                      <a href="assets/img/website_download/oattm_roblox_classic_t-shirt_car_free.png" download className="btn btn-style-two">Download Roblox T-Shirt</a>
                    </div>
                    


                  </div>
                </div>

          </div>
        </div>
      </section>
  )
}

export default RobloxArea
import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import SingleArea from './SingleArea';
import ReleaseBook from './ReleaseBook'


function BookSingle() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<SingleArea/>
	<ReleaseBook/>
	</main>
	<Footer/>
	</>
  )
}

export default BookSingle